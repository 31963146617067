import React, { useState } from 'react';

import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import MoneyIcon from '@material-ui/icons/Money';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { useAuth } from 'hooks';
import getConfig from 'next/config';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from 'routes';

import Background from 'components/@common/Background';
import GradientTextButton from 'components/@common/Button/GradientTextButton';
import ConfirmationDialog from 'components/@common/ConfirmationDialog';
import NotLoggedInCta from 'components/@home/NotLoggedInCta';
import {
  ContactUs,
  FrequentlyAskedQuestions,
  Language,
  LogOut,
  PrivacySettings,
} from 'components/@icons';
import Link from 'components/@navigation/Link';

const { publicRuntimeConfig: config } = getConfig();

interface Props {
  open: boolean;
  onClose: () => void;
}

const MENU_ITEMS = [
  {
    title: 'menu.title.help',
    items: [
      {
        title: 'menu.faq.title',
        Icon: FrequentlyAskedQuestions,
        url: Routes.faq,
      },
      {
        title: 'menu.pwa.title',
        Icon: SaveAlt,
        url: Routes.pwa,
      },
      {
        title: 'menu.contact.title',
        Icon: ContactUs,
        url: Routes.contact,
      },
    ],
  },
  {
    title: 'menu.title.preferences',
    items: [
      {
        title: 'settings.rewards.title',
        Icon: CardGiftcardIcon,
        url: Routes.rewards,
      },
      {
        title: 'settings.my_interests.title',
        Icon: VideogameAssetIcon,
        url: Routes.interests,
      },
      {
        title: 'settings.my_subscriptions.title',
        Icon: AccountBalanceWalletIcon,
        url: Routes.subscriptions,
      },
      {
        title: 'settings.my_credit.title',
        Icon: MoneyIcon,
        url: Routes.credit,
      },
      {
        title: 'settings.reset_password.title',
        Icon: VpnKeyIcon,
        url: config.DIGI_RESET_PASSWORD_URL,
        target: '_blank',
      },
    ],
  },
  {
    title: 'menu.title.settings',
    items: [
      {
        title: 'menu.appearance.title',
        Icon: WbIncandescentIcon,
        url: Routes.appearance,
      },
      {
        title: 'menu.language.title',
        Icon: Language,
        url: Routes.language,
      },
      {
        title: 'menu.privacy.title',
        Icon: PrivacySettings,
        url: Routes.privacySettings,
      },
    ],
  },
];

const useStyles = makeStyles(theme => ({
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 44,
    color: theme.palette.text.secondary,
  },
  itemText: {
    marginRight: theme.spacing(8),
  },
}));

const MenuDrawer = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const { logout, isLoggedIn } = useAuth();
  const intl = useIntl();
  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleLogoutClick = () => {
    logout();
    setLogoutOpen(true);
  };
  const handleDigiLogout = () => {
    if (typeof window !== 'undefined') {
      window.open(config.DIGI_LOGOUT_URL, '_blank')?.focus();
    }
    setLogoutOpen(false);
    onClose();
  };

  const handleDigiLogoutCancel = () => {
    setLogoutOpen(false);
    onClose();
  };

  const items = MENU_ITEMS.filter(item => {
    if (isLoggedIn) {
      return item;
    }
    return item.title === 'menu.title.settings' || item.title === 'menu.title.help';
  });

  return (
    <Drawer anchor="left" open={open} onClose={onClose} style={{ zIndex: 1300 }}>
      <Background />
      <Box
        my={4}
        minWidth={300}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          {items.map(menuItem => (
            <List
              key={menuItem.title}
              disablePadding
              component="nav"
              aria-labelledby={`${menuItem.title}-subheader`}
              subheader={
                <ListSubheader
                  component="div"
                  disableSticky
                  id={`${menuItem.title}-subheader`}
                  className={classes.subHeader}
                >
                  <Typography variant="subtitle2">
                    <FormattedMessage id={menuItem.title} />
                  </Typography>
                </ListSubheader>
              }
            >
              {menuItem.items.map(({ title, Icon, url }) => (
                <ListItem button key={title} component={Link} href={url}>
                  <ListItemIcon>
                    <Icon width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText className={classes.itemText}>
                    <Typography variant="body1">
                      <FormattedMessage id={title} />
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          ))}
        </Box>

        <NotLoggedInCta px={4} width="100%" />

        {isLoggedIn && (
          <Box px={4} mt={4} width="100%">
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={handleLogoutClick}
              startIcon={<LogOut />}
            >
              <FormattedMessage id="menu.button.logout" />
            </Button>
          </Box>
        )}
      </Box>
      <ConfirmationDialog
        isOpen={logoutOpen}
        onClose={handleDigiLogoutCancel}
        title={intl.formatMessage({ id: 'menu.logout.digi.title' })}
        description={intl.formatMessage({ id: 'menu.logout.digi.description' })}
        primaryButton={
          <GradientTextButton onClick={handleDigiLogout} size="medium">
            <FormattedMessage id="menu.logout.digi.button" />
          </GradientTextButton>
        }
        secondaryButton={
          <Button color="primary" onClick={handleDigiLogoutCancel} size="medium">
            <FormattedMessage id="general.button.cancel" />
          </Button>
        }
      />
    </Drawer>
  );
};

export default MenuDrawer;
