import * as React from 'react';

function SvgFrequentlyAskedQuestions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Frequently_Asked_Questions_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Frequently_Asked_Questions_svg__a)">
        <path
          d="M12.5 3a8.5 8.5 0 000 17h.5v3c4.86-2.34 8-7 8-11.5A8.506 8.506 0 0012.5 3zm1 14.5h-2v-2h2zm0-3.5h-2c0-3.25 3-3 3-5a2 2 0 00-4 0h-2a4 4 0 018 0c0 2.5-3 2.75-3 5z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgFrequentlyAskedQuestions = React.memo(SvgFrequentlyAskedQuestions);
export default MemoSvgFrequentlyAskedQuestions;
