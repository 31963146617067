import * as React from 'react';

function SvgLogOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Log_Out_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Log_Out_svg__a)">
        <path
          d="M14.111 3h-2.222v11.111h2.222zm5.367 2.411L17.9 6.989A7.689 7.689 0 0120.778 13 7.778 7.778 0 118.089 6.978L6.522 5.411A9.991 9.991 0 1023 13a9.925 9.925 0 00-3.522-7.589z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgLogOut = React.memo(SvgLogOut);
export default MemoSvgLogOut;
