import React from 'react';

import { Box, BoxProps, Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useAuth } from 'hooks';
import { useAnalytics } from 'hooks';
import useMounted from 'hooks/mounted';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Events } from 'constants/events';

import Link from 'components/@navigation/Link';

const { publicRuntimeConfig: config } = getConfig();

export const StyledButtton = styled(Button)`
  font-size: 15px;
`;
export const PrimaryButton = styled(Button)`
  line-height: 16px;
  height: 100%;
  min-height: 42px;
`;

interface Props extends BoxProps {
  icon?: JSX.Element;
  title?: string;
  description?: string;
  textAlign?: 'center' | 'auto' | 'left' | 'right';
  titleVariant?: 'h4' | 'h5' | 'h6';
}

const NotLoggedInCta: React.FC<Props> = ({
  icon,
  title,
  description,
  textAlign = 'left',
  titleVariant = 'h4',
  ...rest
}) => {
  const isMounted = useMounted();
  const { isLoggedIn } = useAuth();
  const { pathname } = useRouter();
  const { send } = useAnalytics();

  if (isLoggedIn) return null;

  return (
    <Box textAlign={textAlign} width="100%" {...rest}>
      {icon && (
        <Box color="grey.100" pb={2}>
          {icon}
        </Box>
      )}
      {title && (
        <>
          {!isMounted ? (
            <Box pb={2} height={50}>
              <Skeleton variant="text" width="60%" height={42} />
            </Box>
          ) : (
            <Box pb={2}>
              <Typography variant={titleVariant} component="h1" color="textPrimary">
                {title}
              </Typography>
            </Box>
          )}
        </>
      )}
      {description && (
        <>
          {!isMounted ? (
            <Box height={{ xs: 48, md: 24 }}>
              <Skeleton variant="text" height="inherit" width="100%" />
            </Box>
          ) : (
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          )}
        </>
      )}
      {!isMounted ? (
        <Box mt={4} mb={2}>
          <Skeleton variant="text" width="100%" height={42} />
        </Box>
      ) : (
        <Box mt={4} mb={2}>
          <PrimaryButton
            //@ts-ignore
            component={Link}
            href={config.DIGI_LOGIN_URL}
            variant="contained"
            color="primary"
            fullWidth
          >
            <FormattedMessage id="home.account.intro.login.button" />
          </PrimaryButton>
        </Box>
      )}
      {!isMounted ? (
        <Box display="flex" justifyContent="center">
          <Skeleton width="80%" height={42} />
        </Box>
      ) : (
        <StyledButtton
          //@ts-ignore
          component={Link}
          href={config.DIGI_REGISTER_URL}
          variant="text"
          color="primary"
          fullWidth
          onClick={() => send({ event: Events.startRegistration, data: { channel: pathname } })}
        >
          <FormattedMessage id="home.account.intro.create_account.button" />
        </StyledButtton>
      )}
    </Box>
  );
};

export default NotLoggedInCta;
