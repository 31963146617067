import React from 'react';

import { Box, useTheme } from '@material-ui/core';
import Image from 'next/image';

interface Props {
  zIndex?: number;
  fixed?: boolean;
}

const Background: React.VFC<Props> = ({ zIndex = -3, fixed = true }) => {
  const theme = useTheme();
  return (
    <Box
      zIndex={zIndex}
      position={fixed ? 'fixed' : 'absolute'}
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <Box position="relative" width="100%" height="100%">
        <Image
          priority
          src={`/branding/img-background-gamenation${
            theme.palette.type === 'dark' ? '-dark' : ''
          }.svg`}
          alt="game nation logo"
          layout="fill"
          objectFit="cover"
        />
      </Box>
    </Box>
  );
};

export default Background;
