import React, { ReactNode } from 'react';

import { Dialog } from '@material-ui/core';

import PaperAction from 'components/@common/PaperAction';

interface Props {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
}

const ConfirmationDialog = ({
  isOpen,
  onClose,
  primaryButton,
  secondaryButton,
  title,
  description,
}: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-label={title} fullWidth>
      <PaperAction
        title={title}
        description={description}
        secondaryButton={secondaryButton}
        primaryButton={primaryButton}
      />
    </Dialog>
  );
};

export default ConfirmationDialog;
