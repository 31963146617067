import * as React from 'react';

function SvgLanguage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Language_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Language_svg__a)">
        <path
          d="M12.99 3A10 10 0 1023 13 10 10 0 0012.99 3zm6.93 6h-2.95a15.649 15.649 0 00-1.38-3.56A8.03 8.03 0 0119.92 9zM13 5.04A14.087 14.087 0 0114.91 9h-3.82A14.087 14.087 0 0113 5.04zM5.26 15A8.242 8.242 0 015 13a8.242 8.242 0 01.26-2h3.38a16.515 16.515 0 00-.14 2 16.515 16.515 0 00.14 2zm.82 2h2.95a15.649 15.649 0 001.38 3.56A7.987 7.987 0 016.08 17zm2.95-8H6.08s2.49-2.93 4.33-3.56A15.649 15.649 0 009.03 9zM13 20.96A14.087 14.087 0 0111.09 17h3.82A14.087 14.087 0 0113 20.96zM15.34 15h-4.68a14.713 14.713 0 01-.16-2 14.585 14.585 0 01.16-2h4.68a14.585 14.585 0 01.16 2 14.713 14.713 0 01-.16 2zm.25 5.56A15.649 15.649 0 0016.97 17h2.95a8.03 8.03 0 01-4.33 3.56zM17.36 15a16.515 16.515 0 00.14-2 16.515 16.515 0 00-.14-2h3.38a8.242 8.242 0 01.26 2 8.242 8.242 0 01-.26 2z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgLanguage = React.memo(SvgLanguage);
export default MemoSvgLanguage;
