import * as React from 'react';

function SvgContactus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Contact_us_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Contact_us_svg__a)">
        <path
          d="M21 3H5a2 2 0 00-1.99 2L3 23l4-4h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zM7 10h12v2H7zm8 5H7v-2h8zm4-6H7V7h12z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgContactus = React.memo(SvgContactus);
export default MemoSvgContactus;
