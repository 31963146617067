import * as React from 'react';

function SvgPrivacysettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Privacy_settings_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Privacy_settings_svg__a)">
        <path
          d="M13 2L4 6v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V13H6V7.3l7-3.11z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgPrivacysettings = React.memo(SvgPrivacysettings);
export default MemoSvgPrivacysettings;
